body {
  padding: 1.8em 2em 2em 2em;
  font-family: 'Segoe UI', 'Tahoma', 'Geneva', 'Verdana', sans-serif;
  max-width: 1200px;
  margin: auto;
  user-select: none;
}

.instruction {
  font-size: 1.2em;
  padding: 0.4em 0 0.3em 0;
}

.instruction .bold {
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.2em;
  margin-bottom: 0.4em;
}

.choicesContainer {
  display: flex;
  flex-grow: 1;
  gap: 1em;
}

.choiceCard {
  border-radius: 10px;
  /* border: 1px solid black; */
  flex-grow: 1;
  flex-shrink: 1;
  padding: 1em 2em 1.6em 2em;
  transition: all 0.1s ease;
  box-shadow: 0px 0px 6px 0px #000;
  background-color: #fff;
  -ms-word-break: break-word;
  word-break: break-word;
  white-space: pre-wrap;
}

.score {
  display: flex;
  justify-content: space-between;
}

.choiceCard.prompt {
  margin-bottom: 1.2em;
  /* position: sticky;
  top: 40px; */
  box-shadow: none;
  border: 1px solid black;
}

.choiceCard.selectable {
  width: 50%;
  background-color: #ddd;
}

.choiceCard.selectable:hover {
  background-color: #fff;
  box-shadow: 0px 0px 2px 0px #000;
  cursor: pointer;
}

.responseName {
  font-size: 1.4em;
  padding-bottom: 0.4em;
  margin-bottom: 0.4em;
  border-bottom: 1px solid black;
}

.reset {
  color: white;
  background-color: #dd0000;
  border-radius: 1.6em;
  padding: 0.8em 1.6em;
  text-align: center;
  box-shadow: 0px 2px 3px 0px #777;
  cursor: pointer;
}

.skip {
  /* position: fixed; */
  /* bottom: 1.2em; */
  /* right: 50%; */
  /* transform: translateX(50%); */
  cursor: pointer;
  border: 1px solid black;
  background-color: white;
  border-radius: 1.6em;
  padding: 0.8em 2em;
  text-align: center;
  box-shadow: 0px 2px 3px 0px #777;
  flex-grow: 1;
}

.h-1 { margin-bottom: 0.1em }
.h-2 { margin-bottom: 0.2em }
.h-3 { margin-bottom: 0.4em }
.h-4 { margin-bottom: 1.2em }
.h-5 { margin-bottom: 2em }
.h-6 { margin-bottom: 4em }

@media screen and (max-width: 900px) {
  .choicesContainer {
    flex-direction: column;
  }

  .choiceCard.selectable {
    width: auto;
  }
}